import React from 'react';
import styled from '@emotion/styled';

interface ILine {
  percentage: number;
}

interface IProgressBar {
  totalSteps: number;
  currentStep: number;
}

const Background = styled.div({
  backgroundColor: 'rgba(255, 255, 255, 0.25)',
  height: 5,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  scrollMargin: 20,
});

const Line = styled.div<ILine>((props) => ({
  backgroundColor: props.theme.colors.white,
  height: '100%',
  width: `${props.percentage}%`,
  transition: 'width 1s',
}));

const ProgressBar: React.FC<IProgressBar> = ({ totalSteps, currentStep }) => (
  <Background>
    <Line percentage={(currentStep * 100) / totalSteps} />
  </Background>
);

export default ProgressBar;
