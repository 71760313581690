import React, { useState, useEffect } from 'react';

import { IReview, IIntro, IQuestionItem } from './FunnelFlow';
import QuestionComponent from './QuestionComponent';
import ReviewComponent from './ReviewComponent';
import IntroComponent from './IntroComponent';

import { useUserAnswersContext } from 'state/UserAnswersStateProvider';

interface IFlowItem {
  flowItemData: IQuestionItem | IReview | IIntro;
  isFirstFlowItem: boolean;
  handleFlowItemSubmit: (value: string[]) => void;
}

const FlowItem: React.FC<IFlowItem> = ({ flowItemData, handleFlowItemSubmit, isFirstFlowItem }) => {
  const { answersById } = useUserAnswersContext();
  const [userAnswers, setUserAnswers] = useState<string[]>([]);

  useEffect(() => {
    if (flowItemData.flowItemType === 'question') {
      setUserAnswers(answersById?.[flowItemData.questionId] || []);
    }
  }, [flowItemData]);

  const handleClick = () => {
    handleFlowItemSubmit(userAnswers);
    setUserAnswers([]);
  };

  const handleRadioFormChange = (value: string[]) => {
    handleFlowItemSubmit(value);
  };

  return (
    <>
      {flowItemData.flowItemType === 'question' && (
        <QuestionComponent
          question={flowItemData}
          onClick={handleClick}
          userAnswers={userAnswers}
          onCheckboxFormChange={setUserAnswers}
          onRadioFormChange={handleRadioFormChange}
          isFirstFlowItem={isFirstFlowItem}
        />
      )}
      {flowItemData.flowItemType === 'review' && (
        <ReviewComponent review={flowItemData} onClick={handleClick} />
      )}
      {flowItemData.flowItemType === 'intro' && <IntroComponent onContinueClick={handleClick} />}
    </>
  );
};

export default FlowItem;
