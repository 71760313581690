import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import FlowItem from './FlowItem';
import { review1, review2, review3, intro } from './constants';

import backgroundImage from 'assets/pngs/intro_background.jpg';
import mainBackgroundImage from 'assets/pngs/main_background.jpg';
import mobileBackgroundImage from 'assets/pngs/mobile_background.jpg';
import FullScreenWrapper from 'components/FullScreenWrapper';
import ProgressBar from 'components/ProgressBar';
import Header from 'components/Header';
import BackHandler from 'components/BackHandler';
import { useUserAnswersContext } from 'state/UserAnswersStateProvider';
import { scrollToTop } from 'utils/helpers';
import { handlePageView } from 'features/Tracking/trackingApiRequests';
import { ROUTES } from 'utils/constants';

export interface IAnswer {
  answer: string;
  id: string;
  icon: string;
  ordering: number;
}

export type QuestionType = 'question' | 'review' | 'intro';

export interface IQuestion {
  node: IQuestionItem;
}

export interface IQuestionItem {
  question: string;
  questionId: string;
  ordering: number;
  type: 'radio' | 'checkbox';
  answers: IAnswer[];
  flowItemType: 'question';
  trackingUrl: string;
}

export interface IReview {
  author: string;
  review: string;
  backgroundImage: string;
  flowItemType: 'review';
  trackingUrl: string;
  hideReviewStart?: boolean;
}

export interface IIntro {
  flowItemType: 'intro';
  trackingUrl: string;
}

interface IFunnelFlow {
  apiQuestions: IQuestion[];
}

const FunnelFlow: React.FC<IFunnelFlow> = ({ apiQuestions }) => {
  const [currentFlowItemIndex, setCurrentQuestionIndex] = useState<number>(0);

  const { storeAnswers, updateAnswers } = useUserAnswersContext();

  const formattedApiQuestions = apiQuestions.map((question) => ({
    ...question.node,
  }));
  const flowData = [...formattedApiQuestions] as (IReview | IQuestionItem | IIntro)[];
  const secondReviewPosition = Math.round(flowData.length / 2) + 1;

  flowData.splice(1, 0, intro);
  flowData.splice(3, 0, review1);
  flowData.splice(secondReviewPosition, 0, review2);
  flowData.push(review3);

  const currentFlowItem = flowData[currentFlowItemIndex];
  const isFirstQuestion = currentFlowItemIndex === 0;

  useEffect(() => {
    handlePageView(currentFlowItem.trackingUrl);
  }, [currentFlowItemIndex]);

  const handleQuestionSubmit = (value: string[]) => {
    const isLastFlowItem = flowData.length === currentFlowItemIndex + 1;

    if (currentFlowItem.flowItemType === 'question' && !value.length) {
      return;
    }

    if (currentFlowItem.flowItemType === 'question') {
      updateAnswers({ [currentFlowItem.questionId]: value });
    }

    if (isLastFlowItem) {
      navigate(ROUTES.PERSONALIZING);
      storeAnswers();

      return;
    }

    setCurrentQuestionIndex(currentFlowItemIndex + 1);
    scrollToTop();
  };

  const handleBackClick = () => {
    if (currentFlowItemIndex === 0) {
      return;
    }

    setCurrentQuestionIndex(currentFlowItemIndex - 1);
  };

  const background =
    isFirstQuestion || currentFlowItem.flowItemType === 'intro'
      ? backgroundImage
      : currentFlowItem.flowItemType === 'review'
      ? currentFlowItem.backgroundImage
      : mainBackgroundImage;

  const mobileBackground =
    isFirstQuestion || currentFlowItem.flowItemType == 'intro'
      ? ''
      : currentFlowItem.flowItemType === 'review'
      ? currentFlowItem.backgroundImage
      : mobileBackgroundImage;

  return (
    <>
      <Header isVisible={isFirstQuestion} />
      <BackHandler isVisible={!isFirstQuestion} onClick={handleBackClick} />
      <FullScreenWrapper
        backgroundImage={background}
        mobileBackgroundImage={mobileBackground}
        contentAlign={currentFlowItem.flowItemType === 'review' ? 'flex-end' : 'center'}
        alignFlexStartOnMobile={currentFlowItem.flowItemType === 'question' && !isFirstQuestion}
      >
        <FlowItem
          isFirstFlowItem={isFirstQuestion}
          flowItemData={currentFlowItem}
          handleFlowItemSubmit={handleQuestionSubmit}
        />
        <ProgressBar currentStep={currentFlowItemIndex} totalSteps={flowData.length} />
      </FullScreenWrapper>
    </>
  );
};

export default FunnelFlow;
