import styled from '@emotion/styled';

import { BREAKPOINTS } from 'utils/constants';

export default styled.form({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '48px 70px',
  width: '100%',
  maxWidth: 385,
  margin: '60px 20px',
  [BREAKPOINTS[500]]: {
    padding: '0 20px',
    margin: '20px 20px 110px 20px',
    maxWidth: '100%',
  },
});
