import React from 'react';
import styled from '@emotion/styled';

import { IQuestionItem } from './FunnelFlow';

import Text from 'components/Text';
import QuestionForm from 'components/QuestionForm';
import Button from 'components/Button';
import { Checkbox, CheckboxGroup } from 'components/Checkbox';

interface IQuestionComponent {
  question: IQuestionItem;
  userAnswers: string[];
  onCheckboxFormChange: (value: string[]) => void;
  onRadioFormChange: (value: string[]) => void;
  onClick: () => void;
  isFirstFlowItem: boolean;
}

interface IQuestionText {
  isLarge: boolean;
}

const StyledButton = styled(Button)({
  marginTop: 26,
  maxWidth: 150,
});

const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const QuestionText = styled(Text)<IQuestionText>((props) => ({
  marginBottom: props.isLarge ? 28 : 10,
  fontWeight: props.isLarge ? 600 : 'normal',
  fontSize: props.isLarge ? 20 : 15,
  lineHeight: props.isLarge ? '32px' : 'auto',
}));

const Title = styled(Text)({
  lineHeight: '36px',
  marginBottom: 8,
});

const Description = styled(Text)({
  lineHeight: '22px',
  color: 'rgba(255, 255, 255, 0.9)',
  marginBottom: 18,
  fontWeight: 400,
});

const BoldDescription = styled.span((props) => ({
  fontWeight: 600,
  color: props.theme.colors.white,
  opacity: 1,
}));

const QuestionComponent: React.FC<IQuestionComponent> = ({
  question,
  onCheckboxFormChange,
  onRadioFormChange,
  userAnswers,
  onClick,
  isFirstFlowItem,
}) => {
  const handleFormChange = (value: string[]) => {
    if (question.type === 'checkbox') {
      onCheckboxFormChange(value);

      return;
    }

    if (question.type === 'radio') {
      onRadioFormChange(value);

      return;
    }
  };

  return (
    <QuestionForm hideBackground={isFirstFlowItem} alignCenter={isFirstFlowItem}>
      {isFirstFlowItem && (
        <>
          <Title type="h1" align="center" weight="bolder">
            Yours is designed for you
          </Title>
          <Description align="center">
            Our goal is to help <BoldDescription>improve</BoldDescription> your
            <BoldDescription> mental </BoldDescription>
            and <BoldDescription>physical health</BoldDescription>
          </Description>
        </>
      )}
      <QuestionText isLarge={!isFirstFlowItem}>{question.question}</QuestionText>
      <Container>
        <CheckboxGroup onChange={handleFormChange} type={question.type} values={userAnswers}>
          {question.answers.map((answer, index) => (
            <Checkbox
              icon={answer.icon}
              index={index}
              key={answer.id}
              value={answer.id}
              label={answer.answer}
            />
          ))}
        </CheckboxGroup>
        {question.type === 'checkbox' && (
          <StyledButton onClick={onClick} text="Continue" isDisabled={!userAnswers.length} />
        )}
      </Container>
    </QuestionForm>
  );
};

export default QuestionComponent;
