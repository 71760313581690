import React from 'react';
import styled from '@emotion/styled';

import { ICheckbox } from './Checkbox';

interface ICheckboxGroup {
  children: React.ReactNode;
  values: string[];
  onChange: (value: string[]) => void;
  type: 'radio' | 'checkbox';
}

const Container = styled.div({
  width: '100%',
});

const CheckboxGroup: React.FC<ICheckboxGroup> = ({ values, onChange, children, type, ...rest }) => {
  const handleChange = (buttonValue: string) => {
    const isRadio = type === 'radio';

    if (isRadio) {
      onChange([buttonValue]);

      return;
    }

    const isCheckboxAndChecked = values.includes(buttonValue);

    if (isCheckboxAndChecked) {
      onChange(values.filter((value: string) => value !== buttonValue));

      return;
    }

    onChange([...values, buttonValue]);
  };

  return (
    <Container {...rest}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as React.ReactElement<ICheckbox>, {
          index,
          checkedValues: values,
          onClick: handleChange,
        })
      )}
    </Container>
  );
};

export default CheckboxGroup;
