import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { ReviewContainer as IntroContainer } from './ReviewComponent';

import Text from 'components/Text';
import Button from 'components/Button';
import InfoForm from 'components/InfoForm';
import LightBulb from 'assets/svgs/light.svg';
import Logo from 'assets/svgs/logo_big.svg';
import DidYouKnow from 'assets/svgs/did_you_know.svg';
import { BREAKPOINTS, ROUTES } from 'utils/constants';

interface IIntroComponent {
  onContinueClick: () => void;
}

const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginBottom: 4,
});

const Title = styled(Text)({
  fontWeight: 600,
  fontSize: 32,
  padding: '0 8px',
  lineHeight: '48px',
  whiteSpace: 'nowrap',
  [BREAKPOINTS[500]]: {
    fontSize: 24,
  },
});

const DidYouKnowContainer = styled.div({
  display: 'flex',
  padding: '16px 24px',
  alignItems: 'center',
  borderBottom: '1px solid rgba(6, 18, 24, 0.1)',
});

const WelcomeText = styled(Text)((props) => ({
  color: props.theme.colors.white,
  opacity: 0.9,
  marginBottom: 28,
  marginTop: 8,
  lineHeight: '22px',
  padding: '0 26px',
  [BREAKPOINTS[500]]: {
    marginTop: 32,
    marginBottom: 24,
    padding: 0,
  },
}));

const StyledIntroContainer = styled(IntroContainer)((props) => ({
  backgroundColor: props.theme.colors.white,
}));

const DidYouKnowText = styled(Text)((props) => ({
  color: props.theme.colors.darkText,
  fontWeight: 600,
  marginLeft: 8,
}));

const MainText = styled(Text)((props) => ({
  color: props.theme.colors.darkText,
  marginLeft: 8,
  padding: '16px 24px',
  lineHeight: '22px',
}));

const BoldText = styled.span({
  fontWeight: 700,
});

const OrangeText = styled(BoldText)((props) => ({
  color: props.theme.colors.orange,
}));

const BeginButton = styled(Button)({
  maxWidth: 140,
});

const SkipButton = styled(Link)({
  background: 'none',
  border: 'none',
  color: 'rgba(255, 255, 255, 0.4)',
  fontSize: 18,
  cursor: 'pointer',
  marginTop: 18,
  textDecoration: 'none',
});

const Description = styled(Text)({
  fontWeight: 400,
  lineHeight: '22px',
  opacity: 0.6,
  marginTop: 24,
  marginBottom: 32,
  padding: '0 20px 0 20px',
});

const StyledLightBulb = styled(LightBulb)({
  paddingRight: 8,
});

const IntroComponent: React.FC<IIntroComponent> = ({ onContinueClick }) => (
  <InfoForm>
    <TitleContainer>
      <Title type="h2">Welcome to</Title>
      <Logo />
    </TitleContainer>
    <WelcomeText align="center">
      A <BoldText>personalized</BoldText> all-in-one app for <BoldText>emotional health</BoldText>
    </WelcomeText>
    <StyledIntroContainer>
      <DidYouKnowContainer>
        <DidYouKnow />
        <DidYouKnowText type="h2">Did you know?</DidYouKnowText>
      </DidYouKnowContainer>
      <MainText>
        <OrangeText>9 out of 10</OrangeText> Yours users expressed an improvement in their
        well-being after a few weeks of using the app.
      </MainText>
    </StyledIntroContainer>
    <Description align="center">
      <StyledLightBulb />
      Answer 8 questions to get personalized content tailored just for you!
    </Description>
    <BeginButton text="Begin" onClick={onContinueClick} />
    <SkipButton to={ROUTES.REGISTER}>Skip for now</SkipButton>
  </InfoForm>
);

export default IntroComponent;
