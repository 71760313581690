import { IReview, IIntro } from './FunnelFlow';

import review1Image from 'assets/pngs/review1.jpg';
import review2Image from 'assets/pngs/review2.jpg';
import review3Image from 'assets/pngs/review3.jpg';

export const intro: IIntro = { flowItemType: 'intro', trackingUrl: '/welcome' };

export const review1: IReview = {
  flowItemType: 'review',
  backgroundImage: review1Image,
  author: 'Psychologist Dr. Jessamy Hibberd',
  trackingUrl: '/success-story-1',
  review:
    '“Checking in with your emotions helps us instantly recommend content according to your mood.”',
  hideReviewStart: true,
};

export const review2: IReview = {
  flowItemType: 'review',
  backgroundImage: review2Image,
  author: 'Isabelle J.',
  trackingUrl: '/success-story-2',
  review:
    'I have been struggling with anxiety all my life. The meditations, stories, music and yoga in nature helped me to feel less trapped and more at ease.',
};

export const review3: IReview = {
  flowItemType: 'review',
  backgroundImage: review3Image,
  author: 'Michelle D.',
  trackingUrl: '/success-story-3',
  review: 'I listen to the sleep stories every night and they always lull me to sleep.',
};
