import React from 'react';
import styled from '@emotion/styled';

import CheckboxMarked from 'assets/svgs/checkbox_marked.svg';

export interface ICheckbox {
  value: string;
  label: string;
  checkedValues?: string[];
  onClick?: (value: string) => void;
  index: number;
  icon: string;
}

interface IStyledButton {
  isChecked?: boolean;
}

const Button = styled.button<IStyledButton>((props) => ({
  borderRadius: 12,
  outline: 'none',
  color: props.theme.colors.white,
  border: `1px solid ${
    props.isChecked ? props.theme.colors.orange : props.theme.colors.checkboxBorder
  }`,
  backgroundColor: `${
    props.isChecked
      ? props.theme.colors.checkboxCheckedBackground
      : props.theme.colors.checkboxBackground
  }`,
  width: '100%',
  height: 50,
  marginBottom: 6,
  paddingLeft: 24,
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  '@media (hover: hover) and (pointer: fine)': {
    ':hover': {
      backgroundColor: !props.isChecked
        ? 'rgba(37, 47, 52, 0.7)'
        : props.theme.colors.checkboxCheckedBackground,
    },
  },
}));

const ButtonText = styled.div({
  fontSize: 15,
});

const IconContainer = styled.div({
  width: 28,
  marginRight: 18,
  textAlign: 'center',
});

const Icon = styled.img({
  height: 28,
});

const MarkedIcon = styled(CheckboxMarked)({
  position: 'absolute',
  right: 0,
  top: 0,
});

const Checkbox: React.FC<ICheckbox> = ({ value, label, checkedValues, onClick, icon, ...rest }) => {
  const handleClick = (e) => {
    e.preventDefault();

    if (onClick) {
      onClick(value);
    }
  };

  const isChecked = checkedValues && checkedValues.includes(value);

  return (
    <Button isChecked={isChecked} onClick={handleClick} {...rest}>
      {icon && (
        <IconContainer>
          <Icon src={icon} />
        </IconContainer>
      )}
      <ButtonText>{label}</ButtonText>
      {isChecked && <MarkedIcon />}
    </Button>
  );
};

export default Checkbox;
