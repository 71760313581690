import styled from '@emotion/styled';

import { BREAKPOINTS } from 'utils/constants';

interface IQuestionForm {
  hideBackground?: boolean;
  alignCenter?: boolean;
}

export default styled.form<IQuestionForm>(({ alignCenter, theme, hideBackground }) => ({
  backgroundColor: hideBackground ? 'unset' : theme.colors.backgroundGray,
  display: 'flex',
  flexDirection: 'column',
  alignItems: alignCenter ? 'center' : 'flex-start',
  padding: '48px 70px',
  width: '100%',
  maxWidth: 323,
  margin: '40px 0',
  [BREAKPOINTS[500]]: {
    maxWidth: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: 0,
    margin: '80px 0 20px 0',
    justifyContent: 'flex-start',
    backgroundColor: 'unset',
  },
}));
