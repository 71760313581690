import React from 'react';
import styled from '@emotion/styled';

import BackArrow from 'assets/svgs/back_arrow.svg';
import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

interface IBackHandler {
  onClick: () => void;
  isVisible: boolean;
}

const Container = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: `32px ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    paddingLeft: MOBILE_SIDE_PADDING,
    paddingRight: MOBILE_SIDE_PADDING,
  },
});

const BackButton = styled.button({
  background: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
});

const BackHandler: React.FC<IBackHandler> = ({ onClick, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Container>
      <BackButton onClick={onClick}>
        <BackArrow />
      </BackButton>
    </Container>
  );
};

export default BackHandler;
