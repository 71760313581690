import React from 'react';
import styled from '@emotion/styled';

import { IReview } from './FunnelFlow';

import Text from 'components/Text';
import InfoForm from 'components/InfoForm';
import Button from 'components/Button';
import ReviewStar from 'assets/svgs/review_star.svg';

interface IReviewComponent {
  review: IReview;
  onClick: () => void;
}

const StyledButton = styled(Button)({
  marginTop: 26,
  maxWidth: 150,
});

const ButtonContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const ReviewContainer = styled.div({
  backgroundColor: 'rgba(6, 18, 24, 0.4)',
  width: '100%',
  maxWidth: 384,
  borderRadius: 4,
});

const ReviewStars = styled.div({
  display: 'flex',
  padding: '16px 24px 0 24px',
});

const ReviewTextContainer = styled.div({
  padding: '24px 24px 32px 24px',
});

const ReviewText = styled(Text)((props) => ({
  color: props.theme.colors.white,
  lineHeight: '19px',
}));

const ReviewAuthor = styled(ReviewText)({
  fontWeight: 600,
  marginTop: 12,
});

const ReviewComponent: React.FC<IReviewComponent> = ({ review, onClick }) => (
  <InfoForm>
    <ReviewContainer>
      {!review.hideReviewStart && (
        <ReviewStars>
          <ReviewStar />
          <ReviewStar />
          <ReviewStar />
          <ReviewStar />
          <ReviewStar />
        </ReviewStars>
      )}
      <ReviewTextContainer>
        <ReviewText type="h2">{review.review}</ReviewText>
        <ReviewAuthor>{review.author}</ReviewAuthor>
      </ReviewTextContainer>
    </ReviewContainer>
    <ButtonContainer>
      <StyledButton onClick={onClick} text="Continue" />
    </ButtonContainer>
  </InfoForm>
);

export default ReviewComponent;
