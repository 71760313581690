import React from 'react';
import { graphql } from 'gatsby';

import FunnelFlow, { IQuestion } from 'features/FunnelFlow';
import SEO from 'components/SEO';
import PublicComponent from 'navigation/PublicComponent';

export const query = graphql`
  query QuestionsQuery {
    allQuestion {
      edges {
        node {
          question
          questionId
          ordering
          type
          flowItemType
          trackingUrl
          answers {
            answer
            id
            ordering
            icon
          }
        }
      }
    }
  }
`;

interface IIndexPage {
  data: {
    allQuestion: {
      edges: IQuestion[];
    };
  };
}

const IndexPage: React.FC<IIndexPage> = ({ data }) => {
  const questions = data.allQuestion.edges;

  return (
    <PublicComponent>
      <SEO />
      <FunnelFlow apiQuestions={questions} />;
    </PublicComponent>
  );
};

export default IndexPage;
